import React from "react";
import "./App.css";

class Contact extends React.Component {
  render() {
    return (
      <div className="Contact">
        <div className="blank"></div>
        <span>"This is Contact Page."</span>
      </div>
    );
  }
}


export default Contact;
