import React from "react";
import "./App.css";

class error404 extends React.Component {
  render() {
    return (
      <div className="page404">
        <div className="blank"></div>
        <h1>"404"</h1>
      </div>
    );
  }
}
export default error404;
