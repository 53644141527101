import React from "react";
import "./App.css";

class Clock extends React.Component {
  constructor() {
    super();
    this.state = {
      time: new Date(),
    };

    /*update time*/

    setInterval(
      function () {
        this.setState({
          time: new Date(),
        });
      }.bind(this),
      1000
    );
  }

  render() {
    return (
      <div className="timer">
        <p className="digit-t">
          {this.state.time.toLocaleTimeString([], {
            hour12: false,
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
          })}
        </p>
      </div>
    );
  }
}
export default Clock;
