import React from "react";
import "./App.css";

class About extends React.Component {
  render() { 
    return ( 
      <div className="About">
      <div className="blank"></div>
      <span>"This is About Page."</span>
    </div>
     );
  }
}
 
export default About;
