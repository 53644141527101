import "./App.css";
import Nav from "./nav";
import About from "./about";
import Contact from "./contact";
import Resume from "./resume";
import Skill from "./skill";
import error404 from "./error404";
import Footer from "./footer";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="App">
        <meta
          name="google-site-verification"
          content="-fbZ81EsWq4_qw57d8nC-s6Mxgnj2fnasdWmIb3aaqY"
        />
        <Nav />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/about" component={About} />
          <Route path="/contact" component={Contact} />
          <Route path="/resume" component={Resume} />
          <Route path="/skill" component={Skill} />
          <Route path="/*" component={error404} />
        </Switch>
      </div>
    </Router>
  );
}

const Home = () => (
  <div>
    <main>
      <div>
        <div>
          <p>不定期更新中......</p>
        </div>
        <img
          alt="center-page"
          src="welcome.jpg"
          width="951px"
          height="713px"
        ></img>
      </div>
    </main>
    <Footer />
  </div>
);
export default App;
