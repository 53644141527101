import React from "react";
import "./App.css";

class Skill extends React.Component {
  render() {
    return (
      <div className="Skill">
      <div className="blank"></div>
      <span>"This is Derex's Skill Page."</span>
    </div>
    );
}
}

export default Skill;
