import React from "react";
import "./App.css";

class Resume extends React.Component {
  render() {
  return (
    <div className="Resume">
      <div>
        <div className="blank"></div>
        <span>"This is Resume Page."</span>
      </div>
    </div>
  );
}
}

export default Resume;
