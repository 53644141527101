import React from "react";
import "./App.css";
import { Link } from "react-router-dom";
import Clock from "./clock";

class Nav extends React.Component {
  render() {
    const listStyle = {
      textDecoration: "none",
    };

    const navItem = ["About", "Skill", "Contact", "Resume"];
    return (
      <nav>
        <div className="nav-list">
          <ul>
            <Link style={listStyle} to="/about">
              <li>{navItem[0]}</li>
            </Link>
            <Link style={listStyle} to="/skill">
              <li>{navItem[1]}</li>
            </Link>
            <Link style={listStyle} to="/contact">
              <li>{navItem[2]}</li>
            </Link>
            <Link style={listStyle} to="/resume">
              <li>{navItem[3]}</li>
            </Link>
            <a className="h-btn" href="/">
              <img alt="Home" src="logo.png" width="50px" height="50px"></img>
            </a>
            <Clock />
          </ul>

        </div>
      </nav>
    );
  }
}

export default Nav;
