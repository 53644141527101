import React from "react";
import "./App.css";

class Footer extends React.Component {
    render() {
        const ftStyle = {
            textDecoration: "none",
            
          };
        const flItem = ["Linkedin", "Weibo"];

        return (
            <div>
                <footer className="footer">
                    <ul>
                        <a style={ftStyle} href="https://www.linkedin.com/in/derexdu/">
                            <li id="ft-link">{flItem[0]}</li>
                        </a>
                        <li>{flItem[1]}</li>

                    </ul>
                </footer>
            </div>

        );
    }
}


export default Footer;
